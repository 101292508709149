<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <table class="col-12 table">
                  <tr>
                    <th style="width: 20%%">{{$t('globalTrans.fiscal_year')}}</th>
                    <th style="width: 1%">:</th>
                    <td style="width: 29%">{{ currentLocale === 'en' ? bcic.fiscalYear_name : bcic.fiscalYear_name_bn }}</td>
                    <th style="width: 20%%">{{$t('globalTrans.month')}}</th>
                    <th style="width: 1%">:</th>
                    <td style="width: 29%">{{ currentLocale === 'en' ? bcic.month_name : bcic.month_name_bn }}</td>
                  </tr>
                  <tr>
                    <th style="width: 20%%">{{$t('bcic.fertilizer_type')}}</th>
                    <th style="width: 1%">:</th>
                    <td style="width: 29%">{{ currentLocale === 'en' ? bcic.type_name : bcic.type_name_bn }}</td>
                    <th style="width: 20%%" v-if="bcic.type == 1">{{$t('globalTrans.country')}}</th>
                    <th style="width: 1%" v-if="bcic.type == 1">:</th>
                    <td style="width: 29%" v-if="bcic.type == 1">{{ currentLocale === 'en' ? bcic.country_name : bcic.country_name_bn }}</td>
                  </tr>
                  <tr v-if="bcic.type == 2">
                    <th style="width: 20%%">{{$t('bcic.total_production')}} ({{ $t('globalTrans.m_ton') }})</th>
                    <th style="width: 1%">:</th>
                    <td style="width: 29%">{{ $n(bcic.total_production) }}</td>
                    <th style="width: 20%%">{{$t('bcic.total_allocation')}} ({{ $t('globalTrans.m_ton') }})</th>
                    <th style="width: 1%">:</th>
                    <td style="width: 29%">{{ $n(bcic.total_allocation) }}</td>
                  </tr>
                  <tr v-if="bcic.type == 2">
                    <th style="width: 20%%">{{$t('bcic.total_distribution')}} ({{ $t('globalTrans.m_ton') }})</th>
                    <th style="width: 1%">:</th>
                    <td style="width: 29%">{{ $n(bcic.total_distribution) }}</td>
                    <th style="width: 20%%">{{$t('bcic.total_stock')}} ({{ $t('globalTrans.m_ton') }})</th>
                    <th style="width: 1%">:</th>
                    <td style="width: 29%">{{ $n(bcic.total_stock) }}</td>
                  </tr>
                </table>
                <b-table-simple class="mt-3" v-if="bcic.type == 1" hover bordered small caption-top responsive>
                  <b-tr>
                    <b-th class="text-center">{{$t('fertilizerConfig.fertilizer_name')}}</b-th>
                    <b-th class="text-center">{{$t('bcic.import')}} ({{ $t('globalTrans.m_ton') }})</b-th>
                    <b-th class="text-center">{{$t('bcic.allocation')}} ({{ $t('globalTrans.m_ton') }})</b-th>
                    <b-th class="text-center">{{$t('bcic.distribution')}} ({{ $t('globalTrans.m_ton') }})</b-th>
                    <b-th class="text-center">{{$t('bcic.stock')}} ({{ $t('globalTrans.m_ton') }})</b-th>
                  </b-tr>
                  <b-tr v-for="(fertilizer, index) in bcic.details" :key="index">
                    <b-td class="text-center">
                      {{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}
                    </b-td>
                    <b-td class="text-center">{{ $n(fertilizer.production) }}</b-td>
                    <b-td class="text-center">{{ $n(fertilizer.allocation) }}</b-td>
                    <b-td class="text-center">{{ $n(fertilizer.distribution) }}</b-td>
                    <b-td class="text-center">{{ $n(fertilizer.stock) }}</b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import seedFertilizerServiceBaseUrl from '@/config/api_config'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getBcicCreateData()
      if (tmp.details.length > 0) {
        const details = tmp.details.map(item => {
            const typeList = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(type => type.value === parseInt(item.fertilizer_id))
            const typeData = {
                value: typeList !== undefined ? typeList.value : '',
                text_en: typeList !== undefined ? typeList.text_en : '',
                text_bn: typeList !== undefined ? typeList.text_bn : ''
            }
            return Object.assign({}, item, typeData)
        })
        tmp.details = details
      }
      this.bcic = tmp
    }
  },
  computed: {
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      bcic: []
    }
  },
  methods: {
    getBcicCreateData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
