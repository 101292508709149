<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
                <b-overlay :show="loadingData">
                    <b-col lg="12" sm="12" class="form-for-textarea">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="fiscal_year_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="bcic.fiscal_year_id"
                                            :options="fiscalYearList"
                                            id="fiscal_year_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Month Name" vid="month_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="month_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{$t('globalTrans.month')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="bcic.month_id"
                                            :options="monthListfiscalYear"
                                            id="month_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Fertilizer Type" vid="type" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="type"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('bcic.fertilizer_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-radio-group
                                                v-model="bcic.type"
                                                :disabled ="id ? true : false"
                                                :options="typeList"
                                                class="mb-3"
                                                value-field="item"
                                                text-field="name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-radio-group>
                                            <div class="invalid-feedback d-block">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            <b-col lg="6" sm="12" v-if="bcic.type == 1">
                                    <ValidationProvider name="Country Name" vid="country_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="country_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{$t('globalTrans.country')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="bcic.country_id"
                                            :options="importCountryList"
                                            id="country_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="bcic.type == 2">
                                    <ValidationProvider name="Total Production" vid="total_production" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="total_production"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('bcic.total_production')}} ({{ $t('globalTrans.m_ton') }}) <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="total_production"
                                                type="number"
                                                v-model="bcic.total_production"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="bcic.type == 2">
                                    <ValidationProvider name="Total Allocation" vid="total_allocation" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="total_allocation"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('bcic.total_allocation')}} ({{ $t('globalTrans.m_ton') }}) <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="total_allocation"
                                                type="number"
                                                v-model="bcic.total_allocation"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="bcic.type == 2">
                                    <ValidationProvider name="Total Distribution" vid="total_distribution" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="total_distribution"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('bcic.total_distribution')}} ({{ $t('globalTrans.m_ton') }}) <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="total_distribution"
                                                type="number"
                                                v-model="bcic.total_distribution"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="bcic.type == 2">
                                    <ValidationProvider name="Total Stock" vid="total_stock" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="total_stock"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('bcic.total_stock')}} ({{ $t('globalTrans.m_ton') }}) <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="total_stock"
                                                type="number"
                                                v-model="bcic.total_stock"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-table-simple class="mt-3" v-if="bcic.type == 1" hover bordered small caption-top responsive>
                                <b-tr>
                                    <b-th class="text-center">{{$t('fertilizerConfig.fertilizer_name')}}</b-th>
                                    <b-th class="text-center">{{$t('bcic.import')}} ({{ $t('globalTrans.m_ton') }})</b-th>
                                    <b-th class="text-center">{{$t('bcic.allocation')}} ({{ $t('globalTrans.m_ton') }})</b-th>
                                    <b-th class="text-center">{{$t('bcic.distribution')}} ({{ $t('globalTrans.m_ton') }})</b-th>
                                    <b-th class="text-center">{{$t('bcic.stock')}} ({{ $t('globalTrans.m_ton') }})</b-th>
                                </b-tr>
                                <b-tr v-for="(fertilizer, index) in bcic.details" :key="index">
                                    <b-td class="text-center">
                                        {{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}
                                    </b-td>
                                    <b-td>
                                        <b-form-input
                                            @input="totalData()"
                                            id="production"
                                            type="number"
                                            v-model="fertilizer.production"
                                        ></b-form-input>
                                    </b-td>
                                    <b-td>
                                        <b-form-input
                                            @input="totalData()"
                                            id="allocation"
                                            type="number"
                                            v-model="fertilizer.allocation"
                                        ></b-form-input>
                                    </b-td>
                                    <b-td>
                                        <b-form-input
                                            @input="totalData()"
                                            id="distribution"
                                            type="number"
                                            v-model="fertilizer.distribution"
                                        ></b-form-input>
                                    </b-td>
                                    <b-td>
                                        <b-form-input
                                            @input="totalData()"
                                            id="stock"
                                            type="number"
                                            v-model="fertilizer.stock"
                                        ></b-form-input>
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                </b-overlay>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <!-- <pre>{{bcic}}</pre> -->
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { bcicCreateStore, bcicCreateUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getLcCreateData()
      if (tmp.details.length > 0) {
        const details = tmp.details.map(item => {
            const typeList = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(type => type.value === parseInt(item.fertilizer_id))
            const typeData = {
                value: typeList !== undefined ? typeList.value : '',
                text_en: typeList !== undefined ? typeList.text_en : '',
                text_bn: typeList !== undefined ? typeList.text_bn : ''
            }
            return Object.assign({}, item, typeData)
        })
        tmp.details = details
      }
      this.bcic = tmp
    } else {
        const fertilizer = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        const data = fertilizer.map(item => {
            const dataAdd = {
                id: 0,
                production: '',
                allocation: '',
                distribution: '',
                stock: '',
                fertilizer_id: item.value
            }
            return Object.assign({}, item, dataAdd)
        })
        this.bcic.details = data
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loadingData: false,
      bcic: {
        id: '',
        fiscal_year_id: 0,
        month_id: 0,
        type: 1,
        country_id: 0,
        total_production: '',
        total_allocation: '',
        total_distribution: '',
        total_stock: '',
        details: [
            {
                entry_id: '',
                fertilizer_id: 0,
                production: '',
                allocation: '',
                distribution: '',
                stock: ''
            }
        ]
      },
      typeList: [
        { item: 1, name: this.$t('bcic.non_uria') },
        { item: 2, name: this.$t('bcic.uria') }
      ]
    }
  },
  computed: {
    fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    monthListfiscalYear: function () {
        return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    importCountryList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getLcCreateData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
        this.loadingData = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }

        if (this.bcic.id) {
            result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${bcicCreateUpdate}/${this.id}`, this.bcic)
        } else {
            result = await RestApi.postData(seedFertilizerServiceBaseUrl, bcicCreateStore, this.bcic)
        }

        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
            this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-4')
            this.loadingData = false
        } else {
            this.$refs.form.setErrors(result.errors)
            this.loadingData = false
        }
    },
    totalData () {
        let totalProduct = 0
        let totalDistribution = 0
        let totalAllocation = 0
        let totalStock = 0
        this.bcic.details.map(item => {
            if (item.production > 0) {
                totalProduct += parseFloat(item.production)
                totalDistribution += parseFloat(item.distribution)
                totalAllocation += parseFloat(item.allocation)
                totalStock += parseFloat(item.stock)
            }
        })
        this.bcic.total_production = totalProduct
        this.bcic.total_distribution = totalDistribution
        this.bcic.total_allocation = totalAllocation
        this.bcic.total_stock = totalStock
    }
  }
}
</script>
