<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bcic.bcic') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                   <b-col lg="6" sm="12">
                        <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('globalTrans.fiscalYear')}}
                                </template>
                                <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Month"  vid="country_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="month_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('fertilizerSales.month')}}
                                </template>
                                <b-form-select
                                plain
                                v-model="search.month_id"
                                :options="monthListfiscalYear"
                                id="month_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Country"  vid="country_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="country_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('globalTrans.country')}}
                                </template>
                                <b-form-select
                                plain
                                v-model="search.country_id"
                                :options="importCountryList"
                                id="country_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Fertilizer Type"  vid="type">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="type"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('bcic.fertilizer_type')}}
                                </template>
                                <b-form-select
                                plain
                                v-model="search.type"
                                :options="typeData"
                                id="type"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col class="col-sm-2">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('bcic.bcic') + ' ' + $t('globalTrans.entry')
                             + ' ' + $t('procurement.list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" variant="primary" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                        <!-- <b-button variant="danger" v-b-modal.modal-5>
                             {{  $t('globalTrans.excelImport') }}
                        </b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(po_memo_no)="data">
                                          {{ $n(data.item.po_memo_no, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(lc_no)="data">
                                          {{ $n(data.item.lc_no, { useGrouping: false }) }}
                                        </template>
                                         <template v-slot:cell(lc_date)="data">
                                           {{ data.item.lc_date|dateFormat }}
                                           </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_view" title="view" v-b-modal.modal-5 size="sm" @click="edit(data.item)">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="$t('bcic.bcic') + ' ' + $t('globalTrans.entry') + ' ' + $t('procurement.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { bcicCreatelist } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            search: {
                fiscal_year_id: 0,
                month_id: 0,
                country_id: 0,
                type: 0
            },
            typeList: [
                { value: 2, text_en: 'Urea', text_bn: 'ইউরিয়া' },
                { value: 1, text_en: 'Non Urea', text_bn: 'নন ইউরিয়া' }
            ],
            typeListData: [
                { value: 2, name: this.$t('bcic.uria') },
                { value: 1, name: this.$t('bcic.non_uria') }
            ]
        }
    },
    computed: {
        fiscalYearList: function () {
            return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        },
        typeData: function () {
            return this.typeList.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        monthListfiscalYear: function () {
            return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
        },
        importCountryList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? `${this.$t('bcic.bcic')} ${this.$t('globalTrans.entry')}` : `${this.$t('bcic.bcic')} ${this.$t('procurement.modify')}`
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
                { label: this.$t('globalTrans.month'), class: 'text-center' },
                { label: this.$t('globalTrans.country'), class: 'text-center' },
                { label: this.$t('bcic.fertilizer_type'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fiscalYear_name_bn' },
                { key: 'month_name_bn' },
                { key: 'country_name_bn' },
                { key: 'type_name_bn' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fiscalYear_name' },
                { key: 'month_name' },
                { key: 'country_name' },
                { key: 'type_name' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        // remove (item) {
        //     this.changeStatus(seedFertilizerServiceBaseUrl, LcCreateToggleStatus, item, 'seeds_fertilizer', 'CnfAgentList')
        // },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, bcicCreatelist, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.formatData(response.data.data))
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        formatData (data) {
            const formattedData = data.map(item => {
                const fiscalYear = this.$store.state.commonObj.fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id && fiscal.status === 0)
                const fiscalYearData = {
                    fiscalYear_name: fiscalYear !== undefined ? fiscalYear.text_en : '',
                    fiscalYear_name_bn: fiscalYear !== undefined ? fiscalYear.text_bn : ''
                }
                const monthListfiscalYear = this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear.find(month => month.value === item.month_id)
                const monthData = {
                    month_name: monthListfiscalYear !== undefined ? monthListfiscalYear.text_en : '',
                    month_name_bn: monthListfiscalYear !== undefined ? monthListfiscalYear.text_bn : ''
                }
                const fertilizerImportCountryList = this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.find(country => country.value === item.country_id && country.status === 1)
                const countryData = {
                    country_name: fertilizerImportCountryList !== undefined ? fertilizerImportCountryList.text_en : '',
                    country_name_bn: fertilizerImportCountryList !== undefined ? fertilizerImportCountryList.text_bn : ''
                }
                const typeList = this.typeList.find(type => type.value === parseInt(item.type))
                const typeData = {
                    type_name: typeList !== undefined ? typeList.text_en : '',
                    type_name_bn: typeList !== undefined ? typeList.text_bn : ''
                }
                return Object.assign({}, item, fiscalYearData, monthData, countryData, typeData)
            })
            return formattedData
        }
    }
}
</script>
