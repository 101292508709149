export const testApi = '/test'

export const lcCreatelist = '/fertilizer/procurements/bcic-create/list'
export const lcCreateStore = '/fertilizer/procurements/bcic-create/store'
export const LcCreateUpdate = '/fertilizer/procurements/bcic-create/update'
export const LcCreateToggleStatus = '/fertilizer/procurements/bcic-create/toggle-status'
export const LcCreateDestroy = '/fertilizer/procurements/bcic-create/destroy'
export const LcCreateShow = '/fertilizer/procurements/bcic-create/show'
export const LcCreateUpdateDateInfo = '/fertilizer/procurements/bcic-create/update-date-info'
// Bcic Create
export const bcicCreatelist = '/fertilizer/bcic/bcic-create/list'
export const bcicCreateStore = '/fertilizer/bcic/bcic-create/store'
export const bcicCreateUpdate = '/fertilizer/bcic/bcic-create/update'
export const bcicCreateDestroy = '/fertilizer/bcic/bcic-create/destroy'
export const bcicCreateShow = '/fertilizer/bcic/bcic-create/show'
